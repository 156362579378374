
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Boxa from "./modules/Boxa.vue";
import Boxb from "./modules/Boxb.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Boxa,
    Boxb,
  },
  data() {
    return {
      // activeItem: 0,
      // itemList: [
      //   { name: "主办单位", id: 1 ,anchor:"a"},
      //   { name: "培训目标", id: 2 ,anchor:"b"},
      //   { name: "培训内容", id: 3 ,anchor:"c"},
      //   { name: "培训对象", id: 4 ,anchor:"d"},
      //   { name: "近期开班", id: 5 ,anchor:"e"},
      //   { name: "相关须知", id: 6 ,anchor:"f"},
      //   { name: "学员好评", id: 7 ,anchor:"g"},
      // ],
      platfromImg: require("@/assets/1new/生涯指导中心/1.jpg"),
      featureImg: require("@/assets/1new/生涯指导中心/2.jpg"),
      caseImg: require("@/assets/1new/生涯指导中心/3.jpg"),
      teamImg: require("@/assets/1new/生涯指导中心/合作单位.jpg"),
      schoolList:["衡水中学","湖州城南实验学校","威海七中","石家庄长安区教育局","鞍山市第八中学","威海新苑中学","衡水一中邯郸分校","鞍山田家炳中学","哈尔滨工业大学附属中学","邢台皇台底中学","鞍山市第十三中学","哈尔滨兆麟中学","泰安英雄山中学","英德市教育局","双鸭山第一中学","泰安东平高级中学","北京师范大学广州实验学校","红兴隆第一高级中学","利津一中","云南衡水实验中学","运城国际学校","水果湖第一中学","威海环翠国际中学","........"]
    };
  },
  methods: {
    // choiceItem(a:number,anchor:string){
    //   this.activeItem = a;
    //      let an = document.getElementById(anchor);
    //     an?.scrollIntoView()
    // }
  },
});
