<template>
  <div class="home">
    <Swiper></Swiper>
    <!-- <SubNav></SubNav>
    <div class="career">
      <Boxa class="boxa"></Boxa>
      <div class="line"></div>
      <div class="nav">
        <span  v-for="(item, i) in itemList" :key="item" @click="choiceItem(i,item.anchor)" :class="activeItem == i ? 'linka' : 'link'"
          >{{item.name}}</span
        >
      </div>
      <Boxb class="boxb"></Boxb>
    </div> -->
    <div class="center">
      <img
        class="platfromImg"
        :src="platfromImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <img
        class="featureImg"
        :src="featureImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <img
        class="caseImg"
        :src="caseImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <img
        class="teamImg"
        :src="teamImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <div class="school">
        <span class="schoolItem" v-for="(item,i) in schoolList" :key="i">{{item}} </span>
      </div>
    </div>
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Boxa from "./modules/Boxa.vue";
import Boxb from "./modules/Boxb.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Boxa,
    Boxb,
  },
  data() {
    return {
      // activeItem: 0,
      // itemList: [
      //   { name: "主办单位", id: 1 ,anchor:"a"},
      //   { name: "培训目标", id: 2 ,anchor:"b"},
      //   { name: "培训内容", id: 3 ,anchor:"c"},
      //   { name: "培训对象", id: 4 ,anchor:"d"},
      //   { name: "近期开班", id: 5 ,anchor:"e"},
      //   { name: "相关须知", id: 6 ,anchor:"f"},
      //   { name: "学员好评", id: 7 ,anchor:"g"},
      // ],
      platfromImg: require("@/assets/1new/生涯指导中心/1.jpg"),
      featureImg: require("@/assets/1new/生涯指导中心/2.jpg"),
      caseImg: require("@/assets/1new/生涯指导中心/3.jpg"),
      teamImg: require("@/assets/1new/生涯指导中心/合作单位.jpg"),
      schoolList:["衡水中学","湖州城南实验学校","威海七中","石家庄长安区教育局","鞍山市第八中学","威海新苑中学","衡水一中邯郸分校","鞍山田家炳中学","哈尔滨工业大学附属中学","邢台皇台底中学","鞍山市第十三中学","哈尔滨兆麟中学","泰安英雄山中学","英德市教育局","双鸭山第一中学","泰安东平高级中学","北京师范大学广州实验学校","红兴隆第一高级中学","利津一中","云南衡水实验中学","运城国际学校","水果湖第一中学","威海环翠国际中学","........"]
    };
  },
  methods: {
    // choiceItem(a:number,anchor:string){
    //   this.activeItem = a;
    //      let an = document.getElementById(anchor);
    //     an?.scrollIntoView()
    // }
  },
});
</script>
<style lang="scss" scoped>
// .career {
//   width: 1200px;
//   height: 100%;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   position: relative;
//   .boxa {
//     margin-left: -360px;
//   }
//   .boxb {
//     margin-left: -360px;
//   }
//   .line {
//     width: 1920px;
//     margin-left: -360px;
//     height: 80px;
//     background-color: #a0d01c;
//   }
//   .nav {
//     width: 1200px;
//     margin-top: 50px;
//     height: 53px;
//     display: block;
//     overflow: hidden;
//     // background-color: pink;
//     .link {
//       width: 170px;
//       height: 50px;
//       line-height: 50px;
//       font-size: 22px;
//       color: #999;
//       text-align: center;
//     // background-color: pink;
//       cursor: pointer;
//     display: inline-block;
//     }
//     .linka {
//       width: 170px;
//       cursor: pointer;
//       height: 50px;
//       line-height: 50px;
//       font-size: 22px;
//       color: #e68725;
//       text-align: center;
//      display: inline-block;
//       border-bottom: 3px solid #e68725;
//     }
//   }
//   .right {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
// }
.center {
  width: 1200px;
  background-color: #fff;
  min-height: 300px;
  margin-top: 24px;
}
.platfromImg {
  width: 1200px;
  height: 1538px;
}
.featureImg {
  width: 1200px;
  height: 3599px;
}
.caseImg {
  width: 1200px;
  height: 2514px;
}
.teamImg {
  width: 1200px;
  height: 107px;
}
.school{
  width: 1200px;
  background-color: #eeefef;
    padding-top: 80px;
  float: left;
  .schoolItem{
    width: 345px;
    height: 26px;
    font-size: 26px;
    float: left;
    margin-left: 44px;
    margin-bottom: 30px;
  }
}
.message {
  width: 1200px;
  // background-color: skyblue;
  display: block;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 56px;
}
</style>
